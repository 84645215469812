import { formatData } from "./../service/table-data";
import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { TableItemProps, LinkedTaskProps } from "@/service/table-data";
import { submitFormFilter } from "@/api/ts/dropdowns_config";

export type FormState = Ref<Record<string, string>>;
// this is our 'risco' app store
interface AppStoreProps {
  dataItems: TableItemProps[];
  filteredDataItems: TableItemProps[];
  initialTableCols: Ref<object[]>;
  favoriteItems: TableItemProps[];
  searchInput: Ref<string>;
  sidebarFilterKey: Ref<string>;
  isShowSidebar: Ref<boolean>;
  currentSelectedPlan: Ref<TableItemProps | null>;
  selectedTaskRow: Ref<LinkedTaskProps | null>;
  formRiskState: FormState;
  filterState: Record<string, any> | null;
}

function isCategoryValue(value: unknown): value is {
  label: string;
  id: number;
  value: { id: number; label: string };
} {
  return (
    typeof value === "object" &&
    value !== null &&
    "value" in value &&
    typeof (value as any).value === "object" &&
    "id" in (value as any).value
  );
}

export const useAppStore = defineStore("appStore", {
  state: (): AppStoreProps => ({
    dataItems: [],
    filteredDataItems: [],
    initialTableCols: ref([]),
    favoriteItems: [],
    searchInput: ref(""),
    sidebarFilterKey: ref(""),
    isShowSidebar: ref(true),
    currentSelectedPlan: ref(null),
    selectedTaskRow: ref(null),
    formRiskState: ref({
      name: "",
      description: "",
      category: "",
      priority: "",
      evaluator: "",
      area: "",
      responsible: "",
    }) as FormState,
    filterState: null,
  }),
  actions: {
    setInitialTableItems(payload: any) {
      const dataFormatted = formatData(payload);
      this.filteredDataItems = [...dataFormatted];
      this.dataItems = [...dataFormatted];
    },
    validateStatus(payload) {
      const validStatuses = [
        "identificado",
        "avaliado",
        "em tratamento",
        "tratado",
        "monitoramento",
      ];
      return validStatuses.includes(payload.toLowerCase());
    },
    setTableFilter(
      status?: string | null,
      risk?: string | null,
      isFavorite: boolean = false,
      customFilters: any = {}
    ) {
      if (this.dataItems.length === 0) return; // Evita tentar filtrar dados inexistentes
      const normalizedStatus =
        typeof status === "string" ? status.toLowerCase() : null;
      const normalizedRisk =
        typeof risk === "string" ? risk.toLowerCase() : null;

      this.filteredDataItems = this.dataItems.filter((item) => {
        // Filtro por status
        const matchesStatus = normalizedStatus
          ? item.state?.toLowerCase() === normalizedStatus
          : true;

        // Filtro por risco
        const matchesRisk = normalizedRisk
          ? item.initial_risk?.toLowerCase() === normalizedRisk
          : true;

        // Filtro por favoritos
        const matchesFavorite = isFavorite
          ? this.favoriteItems.some((favorite) => favorite.Id === item.Id)
          : true;

        // Filtros customizados
        const matchesCustomFilters = Object.keys(customFilters).every((key) => {
          const value = customFilters[key];

          if (key === "category" && isCategoryValue(value)) {
            return item.category === value.label;
          }

          if (key === "evaluator" && isCategoryValue(value)) {
            return item.id_user_appraiser === value.value.id;
          }

          if (key === "treatment_result" && isCategoryValue(value)) {
            return item.treatment_result === value.value.id;
          }

          if (key === "initialRisk") {
            return typeof value === "number" || typeof value.label === "string"
              ? item.atual_risk === value.label
              : isCategoryValue(value) && item.atual_risk === value.label;
          }

          if (key === "residualRisk") {
            return typeof value === "number" || typeof value.label === "string"
              ? item.residual_risk === value.label
              : isCategoryValue(value) && item.residual_risk === value.label;
          }

          if (key === "startDate") {
            return typeof value === "number" || typeof value === "string"
              ? item.created_at === value
              : isCategoryValue(value) && item.created_at === value;
          }

          if (key === "endDate") {
            return typeof value === "number" || typeof value === "string"
              ? item.created_at === value
              : isCategoryValue(value) && item.created_at === value;
          }

          if (key === "status" && typeof value === "string") {
            return item.state?.toLowerCase() === value.toLowerCase();
          }

          return true;
        });

        // if (isFavorite && this.favoriteItems.length > 0) {
        //   return matchesFavorite && matchesCustomFilters;
        // }

        // Combina todos os filtros
        return (
          matchesStatus &&
          matchesRisk &&
          matchesFavorite &&
          matchesCustomFilters
        );
      });
    },
    setTableCustomFilter(customFilters: any) {
      if (Object.keys(customFilters).length === 0) {
        this.filterState = null;
        this.filteredDataItems = this.dataItems;
        return;
      }

      this.filterState = {
        ...this.filterState,
        ...customFilters,
      };

      submitFormFilter(this.filterState)
        .then((filteredData) => {
          this.filteredDataItems = formatData(filteredData);
        })
        .catch((error) => {
          console.error("Erro ao buscar dados filtrados:", error);
        });
    },
    resetCustomFilter() {
      this.filterState = null;
      this.selectedStatus = null;
      this.selectedRisk = null;
      this.filteredDataItems = [...this.dataItems];
    },
    addRowToFavorite(payload: TableItemProps) {
      const alreadyFavorited = this.favoriteItems.some(
        (item) => item.Id === payload.Id
      );
      if (!alreadyFavorited) {
        this.favoriteItems.push(payload);
      }
    },
    removeRowFromFavorite(payload: any) {
      const index = this.favoriteItems.findIndex(
        (item) => item.Id === payload.Id
      );
      if (index !== -1) {
        this.favoriteItems.splice(index, 1);
      }
    },
    setSearchInput(payload: string) {
      this.searchInput = payload;
    },
    setSidebarFilterKey(payload: string) {
      this.sidebarFilterKey = payload;
    },
    toggleSidebar() {
      this.isShowSidebar = !this.isShowSidebar;
    },
    setCurrentSelectedPlan(payload: TableItemProps | null) {
      if (this.dataItems.length === 0) return;
      this.currentSelectedPlan = payload;
    },
    setSelectedTaskRow(payload: LinkedTaskProps | null) {
      this.selectedTaskRow = payload;
    },
  },
});
function formatStatus(status: any) {
  throw new Error("Function not implemented.");
}
